import Vue from 'vue'

/* External deps */
import * as Sentry from '@sentry/vue'

/* Font */
import '@fontsource/roboto/latin-300.css'
import '@fontsource/roboto/latin-400.css'
import '@fontsource/roboto/latin-500.css'
import '@fontsource/roboto/latin-700.css'

// @ts-ignore
import '@/assets/styles/main.scss'

import Dayjs from '@/plugins/dayjs'
Vue.use(Dayjs)

import { registerNumeric } from '@/lang/numeralLocales'

/* App modules */
const i18nModule = () => import(/* webpackChunkName: 'i18n' */ '@/lang')
const storeModule = () => import(/* webpackChunkName: 'store' */ '@/store')
const routerModule = () => import(/* webpackChunkName: 'router' */ '@/router')
const vuetifyModule = () => import(/* webpackChunkName: 'vuetify' */ '@/plugins/vuetify')

/* init sentry */
Sentry.init({
  Vue,
  dsn: 'https://f56d87b91d2d4194bb34d27ca394ea3f@o1134205.ingest.sentry.io/6181434',

  beforeSend(event: Sentry.Event) {
    if (String(event.level) !== 'exception') {
      return null
    }

    return event
  }
})

/* App */
const App = () => import(/* webpackChunkName: 'appvue' */ './App.vue')

Vue.config.productionTip = false

export const eventBus = new Vue()

Vue.prototype.$priceFormat = registerNumeric

/**
 * Function which dynamically imports dependencies
 */
async function importVueModules() {
  const [i18n, store, router, vuetify] = await Promise.all([
    i18nModule().then(m => m.i18n),
    storeModule().then(m => m.default),
    routerModule().then(m => m.default),
    vuetifyModule().then(m => m.default)
  ])

  return { i18n, store, router, vuetify }
}

/**
 * Fetches user configuration
 */
function fetchAndStoreClientConfig() {
  /* Get absolute root */
  const currentRootPath = window.location.origin +
    window.location.pathname.replace(/(\/thank\-you|\/style\-guide|\/$)/g, '')

  /* Set langedPath */
  localStorage.setItem('langedPath', currentRootPath)

  return Promise.resolve()
}

/**
 * Mounts Vue application
 */
async function mountVue(modules: Object) {
  const app = (await App()).default

  new Vue({
    ...modules,

    render: h => h(app),
  }).$mount('#app')
}

/* Start */
fetchAndStoreClientConfig()
  .then(importVueModules)
  .then(mountVue)
  .catch(e => console.error(e))
