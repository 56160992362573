export class GenericAppState {
  protected _locale: string | null = null
  protected _currency = 'CHF'
  protected _currentProperty: number | null = null

  constructor() {}

  /**
   * Getter for the session currency
   */
  get currency() {
    return this._currency
  }

  set currency(_value) {
    console.warn(`Setting currency is not supported. The default currency is: ${this.currency}`)
  }

  /**
   * Getter for current property ID
   */
  get currentProperty() {
    return this._currentProperty
  }

  /**
   * Setter for current property ID
   */
  set currentProperty(value) {
    this._currentProperty = value
  }

  /**
   * Getter for standardized locale
   * Format used is similar to ISO/IEC 15897 (e.g. en-US)
   * @returns {String?}
   */
  get locale(): string | null {
    return this._locale
  }

  /**
   * Setter for locale in standardized format
   * Format used is similar to ISO/IEC 15897 (e.g. en-US)
   * @param {String} value String to search for
   */
  set locale(value: string | null) {
    this._locale = value
  }
}

export default GenericAppState
