import GenericAppState from './generic.state'
import { findLocale, getLanguage } from '@/lang/helper'
import type { IVueI18n } from 'vue-i18n'

interface RequestedRoomRatePlan {
  rateid: string | null
  roomid: string | null

  paramNames: {
    rateid?: string
    roomid?: string
  }
}

export class AppState extends GenericAppState {
  private _i18n: IVueI18n | null = null
  private _isSessionExpired: boolean = false
  private _requestedRoomRatePlan: RequestedRoomRatePlan | null = null
  private _sessionExpiredListeners: ((v: boolean) => void)[] = []
  private _sessionToken: string | null = null

  /**
   * Getter for current property ID
   */
  get currentProperty() {
    if (this._currentProperty) {
      return this._currentProperty
    }

    /* Default to query params */
    const appQueryParams = new URL(window.location.href).searchParams
    const paramProperty = appQueryParams.get('propertyId')

    return paramProperty != null
      ? +paramProperty
      : null
  }

  /**
   * Setter for current property ID
   */
  set currentProperty(value) {
    this._currentProperty = value
  }

  get isSessionExpired() {
    return this._isSessionExpired
  }

  set isSessionExpired(value) {
    this._isSessionExpired = value

    /* Notify listeners */
    for (const listener of this._sessionExpiredListeners) {
      listener(value)
    }
  }

  get onSessionExpired() {
    return this._sessionExpiredListeners
  }

  set onSessionExpired(value) {
    if (value == null) { this._sessionExpiredListeners = [] }

    else if (Array.isArray(value) && value.every(item => typeof item === 'function')) {
      Array.prototype.push.apply(this._sessionExpiredListeners, value)
    }

    else if (typeof value === 'function') {
      this._sessionExpiredListeners.push(value)
    }

    else {
      throw TypeError('Invalid argument, expected function, array of functions or null')
    }
  }

  /**
   * Remove a listener
   * @param {function} value
   */
  set offSessionExpired(value: (v: boolean) => void) {
    if (typeof value !== 'function') {
      throw TypeError('Invalid argument, expected function')
    }

    const index = this._sessionExpiredListeners.indexOf(value)
    if (index === -1) return

    this._sessionExpiredListeners.splice(index, 1)
  }

  /**
   * Gets the requested room/rateplan ids
   * @returns {RequestedRoomRatePlan | null}
   */
  get requestedRoomRatePlan(): RequestedRoomRatePlan | null {
    if (this._requestedRoomRatePlan) {
      return this._requestedRoomRatePlan
    }

    /* Get the url params */
    const params = Array.from(
      new URL(window.location.href)
        .searchParams
        .entries()
    )

    /* Resulting object */
    const result: RequestedRoomRatePlan = {
      rateid: null,
      roomid: null,
      paramNames: {}
    }

    /* Iterate over params */
    for (const [paramName, paramValue] of params) {
      if (paramName.toLowerCase() === 'rateid') {
        result.rateid = paramValue
        result.paramNames.rateid = paramName
      } else if (paramName.toLowerCase() === 'roomid') {
        result.roomid = paramValue
        result.paramNames.roomid = paramName
      }
    }

    /* Cache the result */
    this._requestedRoomRatePlan = (result.rateid || result.roomid)
      ? result
      : null

    /* Return */
    return this._requestedRoomRatePlan
  }

  /**
   * get session token
   * @returns {string|null}
   */
  get sessionToken(): string | null {
    let token = null

    /* if token exists, return it */
    if (this._sessionToken) {
      return this._sessionToken
    }

    /* Get the url params */
    const params = Array.from(
      new URL(window.location.href)
        .searchParams
        .entries()
    )

    /* Iterate over params */
    for (const [paramName, paramValue] of params) {
      if (paramName.toLowerCase() === 'sessiontoken') {
        token = paramValue
      }
    }

    /* cache */
    this._sessionToken = token

    return token
  }

  /**
   * Setter for i18n object. Needed 
   */
  set i18n(value: IVueI18n) {
    this._i18n = value
    this._locale = (value && value.locale) || null
  }

  /**
   * Getter for standardized locale
   * Format used is similar to ISO/IEC 15897 (e.g. en-US)
   * @returns {string?}
   */
  get locale(): string | null {
    return this._locale
  }

  /**
   * Setter for locale in standardized format
   * Format used is similar to ISO/IEC 15897 (e.g. en-US)
   * @param {String} value String to search for
   */
  set locale(value: string | null) {
    /* No locale supplied */
    if (!value) return

    const foundLocale = findLocale(value, value.length <= 2)

    /* Check if nothing has been found */
    if (!foundLocale) return

    /* Assign */
    this._locale = foundLocale
    if (this._i18n) this._i18n.locale = foundLocale
  }

  /**
   * Getter for short form of locale
   * @returns {String?} Short form of locale, e.g. `en`
   */
  get localeShort(): string | null {
    const localeFull = this.locale
    return localeFull && getLanguage(localeFull)
  }

  /**
   * Getter for i18n used language. E.g. `en-GB` or `de-DE`
   */
  get language() {
    return this._i18n && this._i18n.locale
  }
}

export default new AppState()
