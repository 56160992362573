import english from './en'
import german from './de'
import italian from './it'
import french from './fr'

export default {
  'en-GB': english,
  'de-DE': german,
  'it-IT': italian,
  'fr-FR': french
}
