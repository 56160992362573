import Vue from 'vue'
import VueI18n from 'vue-i18n'
import messages from '@/assets/langs'
import AppState from '@/utils/app.state'

Vue.use(VueI18n)

/**
 * Define number formats for the application
 */
const DEFAULT_NUMBER_FORMATS = {
  currency: {
    style: 'currency',
    currency: AppState.currency,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }
}

const numberFormats = {
  'en-GB': DEFAULT_NUMBER_FORMATS,
  'de-DE': DEFAULT_NUMBER_FORMATS,
  'fr-FR': DEFAULT_NUMBER_FORMATS,
  'it-IT': DEFAULT_NUMBER_FORMATS
}

export const i18n = new VueI18n({
  locale: 'en-GB',
  messages,
  numberFormats,
  fallbackLocale: 'en-GB',
  // maximumFractionDigits: 2,
  // minimumFractionDigits: 2
})

export function getCode(): string | undefined {
  return getLangCode()?.split('-')[0]
}

export function getLangCode(): string | undefined {
  return i18n.locale
}

export function convertHtml(value: string, convertToText = false): string {
  const parser = new DOMParser()

  const dom = parser.parseFromString(
    '<!doctype html><body>' + value,
    'text/html'
  )

  const decodedString = convertToText
    ? dom.body.textContent
    : dom.body.innerHTML

  return decodedString || ''
}

/**
 * Parses structure like { [locale]: Array<String> }
 * @param {{ [String]: Array<String> }} value 
 * @returns {String | undefined} Parsed value
 */
export function getStringFromLangedArray(value: Record<string, string[]>): string | undefined {
  if (!value) return

  /* Get current language */
  const locale = i18n.locale

  /* Get fallback lang */
  const fallBackLang = Object.keys(value)[0]

  /* Get an array */
  const langedArray = value[locale] || value[fallBackLang]

  return langedArray && langedArray.length
    ? langedArray[0]
    : undefined
}

export function getStringFromArray(
  array: ({ languageCode: string } & Record<string, string>) [],
  convertToText = true,
  key = 'description'
) {
  if (!Array.isArray(array)) return ''

  /* Current language code */
  const currentLanguageCode = getCode()?.toUpperCase()

  /* Double search: either a direct match or EN */
  let match
  for (const element of array) {
    const elementLanguageCode = element.languageCode.toUpperCase()

    if (elementLanguageCode === currentLanguageCode) {
      match = element
      break
    } else if (elementLanguageCode === 'EN') {
      match = element
    }
  }

  /* Return converted match or empty string */
  return match && match[key] != null
    ? convertHtml(match[key], convertToText)
    : null
}

// Fn uses by `Extra.js` accessor `description`.
export function getStringFromArrayExtra(value: Record<string, Record<string, string>>): Record<string, string> {
  const fallBackLang = Object.keys(value)[0]
  const locale = getLangCode()?.split('-')[0].toUpperCase()

  const descriptionObject = locale && value.hasOwnProperty(locale)
    ? value[locale]
    : value[fallBackLang]

  if (descriptionObject) {
    Object.keys(descriptionObject).forEach(key => {
      descriptionObject[key] = convertHtml(descriptionObject[key], true)
    })
    return descriptionObject
  } else {
    return {}
  }
}

export function getKeyedArray(value: Record<string, string>) {
  const currentLocale = getCode()?.toUpperCase()
  const firstObject = Object.keys(value)[0]
  return currentLocale && value.hasOwnProperty(currentLocale)
    ? value[currentLocale]
    : value[firstObject]
}

export function getFromObjectKey(value: Record<string, Record<string, string>>, key: string) {
  const currentLocale = getCode()?.toUpperCase()
  const firstObject = Object.keys(value)[0]
  return currentLocale && value.hasOwnProperty(currentLocale)
    ? value[currentLocale][key]
    : value[firstObject][key]
}

export function getFromObject(
  value: Record<string, Record<string, string>> | Record<string, string>[],
  key: string,
  langCode = 'langCode'
) {
  const locale = i18n.locale
  const currentLang = locale.split('-')[0].toUpperCase()

  if (Array.isArray(value)) {
    const currentLangValue = value.find(lang => lang[langCode] === currentLang && lang.hasOwnProperty(key))
    const defaultValue = value.find(lang => lang[key] !== null)

    if (currentLangValue && currentLangValue[key]) {
      return currentLangValue[key]
    } else if (defaultValue) {
      return defaultValue[key]
    } else {
      return ''
    }
  } else if (typeof value === 'object') {
    return value.hasOwnProperty(currentLang)
      ? value[currentLang][key]
      : ''
  }
}
