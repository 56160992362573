import { LangedItem } from '../domain/model/basics'
import { i18n } from './index'

/**
 * Finds locale in available locales
 * @param {String} locale 
 * @param {Boolean} isShortForm 
 */
export function findLocale(locale: string, isShortForm: boolean) {    
  const searchFn = isShortForm
    /* Search for occurrence independent of register case */
    ? (l: string) => l.toLowerCase().includes(locale.toLowerCase())
    /* Search for exact match */
    : (l: string) => l === locale

  return i18n.availableLocales.find(searchFn)
}

/**
 * Gets the language component of a locale
 * @param {String} locale The locale in form of <language>-<territory>, e.g. `en-US`
 * @returns {String} The first component of the locale, e.g. `en-US` -> `en`
 */
export function getLanguage(locale: string): string {
  return locale.slice(0, locale.indexOf('-'))
}

export function extractTranslation (toTranslate: LangedItem[] | undefined) {
  return toTranslate && toTranslate[0] && toTranslate[0].value
}
