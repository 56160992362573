import { i18n, getLangCode } from './index'

declare module 'vue/types/vue' {
  interface Vue {
    $priceFormat: typeof registerNumeric
  }
}

export function registerNumeric(value: number) {
  const locale = getLangCode()
  return i18n.n(value, 'currency', locale || 'en-GB')
}
